import { KeyMap, PhysicalAddressDto } from '..'
import { AssetDto } from './asset.dto'
import { GeoDto } from './geo.dto'
import { ItemInfoDto } from './itemInfo.dto'

export class ItemDto {
  iProofId: string
  hierarchy: number = 0
  hierarchyName: string
  type: number = -1
  lotId?: string
  skuId: string
  sku: string
  orderId = 0
  companyId: number = 0
  companyName: string
  
  children?: ItemInfoDto[] = []                  // iProofId, stamp, hierarchy, hierarchyName
  numChildren: number = 0
  parents?: ItemInfoDto[] = []     // could be more than one

  assets?: AssetDto[] = []      // * string, file paths
  assetCount: number = 0                      // uploaded assets
  transactions: KeyMap<any>[]           // list of item transactions

  description?: string[] = []                 // * sku-lot-unit description[3]
  descriptionOneLine: string
  url: string[] = []            // * url
  state: string                 // item state
  tags: string[] = []           // * inherited tags
  allTagsAndOrder? : KeyMap<string> = { }         // grouped tags
  tagDetails: [][] = []         // tags by type
  allKeys: string[] = []        // all company keys
  keyValues: KeyMap<string> = {}                // * series of key-value pairs
  keyValueDetails: KeyMap<string>[]         // key values by type
  pins?: GeoDto[] = []             // geo
  locationCode: string          // unique name of location code
  location: PhysicalAddressDto

  itemLocationCode: string
  itemPosition: string

  constructor(some?: Partial<ItemDto>) {
    Object.assign(this, some)
    this.tags ??= ['', '', '']
    this.tagDetails ??= [[], [], []]
  }
}
