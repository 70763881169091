import { Badge } from '../models/badge';
import { SkuDto } from './sku.dto';
import { KeyMap, pick } from '..'

export class LotDto {

  public static Keys = ['iProofId', 'description', 'url', 'tags', 'keyValues', 'parentId'];

  iProofId: string
  description?: string
  url?: string
  tags: string[] = []
  keyValues?: KeyMap<string> = {}
  parentId: string
  
  // assigned
  numItems: number = 0
  badges?: Badge[] = []
  parent?: SkuDto
  match?: boolean
  addItems: number = 0

  constructor(some?: Partial<LotDto>) {
    Object.assign(this, some)
  }
}
