import { StringNullableChain } from "lodash"
import { KeyMap } from ".."
import { LotDto, SkuDto, ScanDto } from "../dto"

export class kv {
  key: string
  value: any
}

export interface LoginBody {
  email: string
  password: string
}

export interface LoginResp {
  email: string
  token2: string
}

export interface ChangePasswordBody {
  email: string
  password?: string
  oldPassword?: string
  code?: string
}

export interface ForgotPasswordBody {
  email: string
  returnUrl: string
}

export class SkuBody {
  static readonly keys: string[] = ["iProofId", "name", "description", "url", "hierarchy", "keyValues", "tags"]

  iProofId: string
  name: string
  description: string
  url: string
  hierarchy: number
  keyValues: KeyMap<string>
  tags: string[]
}

export interface SkusResp extends SkuDto {
  lots: LotDto[]
}

export class LotBody {
  static readonly keys: string[] = ["iProofId", "parentId", "description", "url", "keyValues", "tags"]

  iProofId: string
  parentId: string
  description: string
  url: string
  keyValues: KeyMap<string>
  tags: string[]

  // calculared
  numItems?: number
}

export interface AddCodesResp {
  iProofId: string
  numItems: number
  description: string
  url: string
  tags: string[]
  keyValues: KeyMap<string>
}

export interface ItemPinsResp {
  numberScans: number
  providedScans: number
  scans: ScanDto[]
  countryCodes: string[]
}

export interface ItemsCsvResp {
  data: Blob,
  filename: string
}

export interface ItemKeysBody {
  origin: string
  iProofIds: string[]
  keyValues: KeyMap<string>
}

export interface LocationBody {
  locationCode?: string
  locationModelId: number
  name: string
  path: string
  parentCode: string
  layout: string
  locationPostion?: string
  isEnabled?: boolean
}

export interface RegisterItemBody {
  iProofId: string
  externalId: string
  hierarchy: number
  name?: string
  description: string
  tags: string[]
}

export interface RegisterCaseBody {
  iProofId: string
  externalId: string
  hierarchy: number
  isSealed: boolean

  lot: string
  customerId: number
  retailerId: number
  quantity: number
  description: string
  bottleSize: string,
  vintage: number,
  condition: string

  tags?: string[]
}

export interface RegisterBottleBody {
  iProofId: string
  externalId: string
  hierarchy: number

  customerId: number
  retailerId: number
  lwinId: number
  vintage: number
  bottleSize: string
  description: string
  condition: string

  tags?: string[]
}

export interface AssignItemBody
{
  parentId: string 
  childId: string 
}

export interface MoveLocationBody {
  locationCode: string
  parentCode: string
  locationPosition: string
}

export interface CheckInBody {
  locationCode: string
  code: string
  isIproofId: boolean
  itemPosition?: string
}

export interface ScanFilterResp {
  numberScans: number
  providedScans: number
  scans: ScanDto[]
  countryCodes: string[]
}

