export class Profile {
  email: string
  emailConfirmed: boolean
  role: string
  firstName?: string
  lastName?: string
  companyId: number
  companyAccess
  
  token?: string
  exp: number
  version: string

  constructor(some?: Partial<Profile>) {
    Object.assign(this, some)
  }

  public get isRoot(): boolean {
    return this.role == "Root"
  }
}
  