import { CommonModule, DatePipe } from "@angular/common"
import { NgModule } from "@angular/core"
import { ReactiveFormsModule, FormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"

import { DisplayMode, ValdemortConfig, ValdemortModule } from "ngx-valdemort"
import { ErrorTemplates } from "./helpers"
import { PrimeModule } from "app/prime.module"

import {
  FBDirective,
  BoldDirective,
  RepeatDirective,
} from "./directives"

import {
  SafePipe
} from "./pipes"
 
const Modules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  PrimeModule,
  ValdemortModule
]

const Directives = [
  ErrorTemplates,
  BoldDirective,
  FBDirective,
  RepeatDirective
]

const Pipes = [
  SafePipe,
  DatePipe
]

@NgModule({
  imports: [...Modules],
  declarations: [...Directives],
  exports: [...Modules, ...Directives],
  providers: [...Pipes]
})
export class SharedModule {
  constructor(config: ValdemortConfig) {
    config.errorClasses = "p-error"
    config.displayMode = DisplayMode.ONE
    config.shouldDisplayErrors = (control, form) => control.dirty || control.touched
  }
}
