// https://danielk.tech/home/angular-http-logging-how-to-monitor-the-slowness-of-your-angular-app

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { finalize, Observable } from "rxjs"
import { NGXLogger } from "ngx-logger"
import { environment } from "environments/environment"

@Injectable()
export class TimerInterceptor implements HttpInterceptor {
  constructor(private log: NGXLogger) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const tsStart = performance.now()
    return next.handle(request).pipe(
      finalize(() => this.logRequestTime(tsStart, request.url, request.method))
    )
  }

  private logRequestTime(tsStart: number, url: string, method: string) {
    const delta = performance.now() - tsStart
    if (url.toLowerCase().startsWith(environment.api)) url = url.slice(environment.api.length)
    this.log.trace(`${method} ${url} ${delta.toFixed(0)} ms`)
  }
}
