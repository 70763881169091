import { NgModule } from '@angular/core'

import { AutoCompleteModule } from "primeng/autocomplete"
import { AutoFocusModule } from 'primeng/autofocus'
import { BadgeModule } from 'primeng/badge'
import { BreadcrumbModule } from 'primeng/breadcrumb'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { CardModule } from 'primeng/card'
import { CascadeSelectModule } from 'primeng/cascadeselect'
import { ChartModule } from 'primeng/chart'
import { CheckboxModule } from 'primeng/checkbox'
import { ContextMenuModule } from 'primeng/contextmenu'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DialogModule } from 'primeng/dialog'
import { DividerModule } from 'primeng/divider'
import { DropdownModule } from 'primeng/dropdown'
import { DynamicDialogModule } from 'primeng/dynamicdialog'
import { FieldsetModule } from 'primeng/fieldset'
import { InputNumberModule } from 'primeng/inputnumber'
import { InputSwitchModule } from 'primeng/inputswitch'
import { InputTextModule } from 'primeng/inputtext'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { ListboxModule } from 'primeng/listbox';
import { MenubarModule } from "primeng/menubar"
import { MenuModule } from 'primeng/menu'
import { MultiSelectModule } from 'primeng/multiselect'
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { PasswordModule } from "primeng/password"
import { SelectButtonModule } from 'primeng/selectbutton'
import { PanelMenuModule } from 'primeng/panelmenu'
import { RadioButtonModule } from 'primeng/radiobutton'
import { SidebarModule } from 'primeng/sidebar'
import { SliderModule } from "primeng/slider"
import { SplitterModule } from 'primeng/splitter'
import { StyleClassModule } from 'primeng/styleclass'
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table'
import { TabViewModule } from 'primeng/tabview'
import { ToastModule } from 'primeng/toast'
import { ToolbarModule } from 'primeng/toolbar'
import { TreeModule } from 'primeng/tree'
import { TreeTableModule } from 'primeng/treetable'
import { TreeSelectModule } from 'primeng/treeselect'
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

const Modules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,

  AutoCompleteModule,
  AutoFocusModule,
  BadgeModule,
  BreadcrumbModule,
  ButtonModule,
  CalendarModule,
  CardModule,
  CascadeSelectModule,
  ChartModule,
  CheckboxModule,
  ContextMenuModule,
  ConfirmDialogModule,
  DialogModule,
  DividerModule,
  DropdownModule,
  DynamicDialogModule,
  FieldsetModule,
  InputNumberModule,
  InputSwitchModule,
  InputTextModule,
  InputTextareaModule,
  ListboxModule,
  MenubarModule,
  MenuModule,
  MultiSelectModule,
  OverlayPanelModule,
  PanelMenuModule,
  PasswordModule,
  RadioButtonModule,
  SelectButtonModule,
  SidebarModule,
  SliderModule,
  SplitterModule,
  StyleClassModule,
  TagModule,
  TableModule,
  TabViewModule,
  ToastModule,
  ToolbarModule,
  TreeModule,
  TreeSelectModule,
  TreeTableModule,
  TriStateCheckboxModule
]

@NgModule({
  imports: [...Modules],
  exports: [...Modules]
})
export class PrimeModule { }
