import _ from "lodash"
import { SelectItemGroup } from "primeng/api"
import { CompanyDto } from "."
import { timeout } from "rxjs"

export interface KeyMap<T> { [key: string]: T }

export interface KeyVal<T> { key: string, value: T }

export function randint(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function sleep(ms): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function paint(json: any) {
  if (typeof json !== "string") {
    json = JSON.stringify(json, undefined, 2);
  }
  // if (environment.verbose) console.log(json);
  const style =
    "<style>.string {color: #285;} .number {color: #25d;} .boolean {color: #f84;} .null {color: #555;} .key {color: #c36;}</style>";
  json = json.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
  // tslint:disable-next-line: max-line-length
  json =
    style +
    json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      (match) => {
        let cls = "number";
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = "key";
          } else {
            cls = "string";
          }
        } else if (/true|false/.test(match)) {
          cls = "boolean";
        } else if (/null/.test(match)) {
          cls = "null";
        }
        return '<span class="' + cls + '">' + match + "</span>";
      }
    );
  return json;
}

export function pastels(n: number) {
  const cols = [];
  const step = 360 / n;
  for (let col = 0; col < 360; col += step) {
    cols.push('hsla(' + Math.floor(col) + ',70%,80%,1)');
  }
  return cols.sort(() => Math.random() - 0.5);
}

export function pick(data: any, keys: string[]) {
  return _.cloneDeep(_.pick(data, keys))
}

export function dump(data: any, title: string = null) {
  if (title) {
    console.log(title.toUpperCase() + ":")
  }
  console.log(JSON.stringify(data, null, 2))
}

export function json(data: any, indent: number = 2) {
  console.log(JSON.stringify(data, null, indent))
}

export function debounce(ms) {
  return function (target: any, key: any, descriptor: any) {
    const oldFunc = descriptor.value
    const newFunc = _.debounce(oldFunc, ms)
    descriptor.value = function () {
      return newFunc.apply(this, arguments)
    }
  }
}

export function flip(obj) {
  const ret = {}
  Object.keys(obj).forEach(key => ret[obj[key]] = key)
  return ret
}

export function toKeyVal<T>(obj: KeyMap<T>): KeyVal<T>[] {
  return Object.keys(obj).map(key => ({ key, value: <T>obj[key] }))
}

/**
 * Create the data structure for the hierarchy dropdown to bind to
 */
export function hierarchyDropdown(company: CompanyDto) {
  const itemTypes: SelectItemGroup[] = []
  if (company?.hierarchyNames) {
    const itemKeys = toKeyVal(company.hierarchyNames) //.filter(n => n.key != "Pallet")
    // item grouped
    itemTypes.push({
      label: "Items", value: "box",
      items: itemKeys.map(item => ({ label: item.key, value: item.value }))
    })
    // location grout
    itemTypes.push({
      label: "Locations", value: "building",
      items: company.locationModels.map(model => ({ label: model.name, value: model.id }))
    })
  }
  return itemTypes
}


export function cleanCode(code: string) {
  // http://vt.ag/0803W3GNAU,03W3GNAU
  let isIproofId = false
  // 1. only consider first part
  let qr = code.split(",")[0]
  // 2. remove hyphens
  qr = code.replace(/-/g, "")
  // 3. check if iProofId
  if (code.includes(".ag/")) {
    qr = code.trim().split("/").pop()
    isIproofId = true
  }
  if (qr.length == 9 && qr.charAt(0) == "8") qr = "0" + qr
  return { qr, isIproofId }
}

export function moveFocus(id: string) {
  setTimeout(() => {
    const elm = document.getElementById(id)
    elm?.focus()
  }, 100)
}
export function selectText(id: string) {
  const elm = document.getElementById(id) as HTMLInputElement
  elm?.select()
}

export function range(n: number): number[] {
  return [...Array(n).keys()]
}

// #region Not Used ----------------------------------------------------------- 

export function serialize(obj = {}) {
  const arr = []
  for (const k of Object.keys(obj)) {
    arr.push(
      `${k}=${encodeURIComponent(
        typeof obj[k] === "string" ? String.prototype.trim.call(obj[k]) : obj[k] === null ? "" : obj[k]
      )}`
    )
  }
  return arr.join("&")
}

export function delEmptyKey(obj: any) {
  const copy = {}
  if (obj === null || obj === undefined || obj === "") {
    return copy
  }
  for (const key in obj) {
    if (obj[key] !== null && typeof obj[key] === "object") {
      copy[key] = this.delEmptyKey(obj[key])
    } else if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
      copy[key] = obj[key]
    }
  }
  return copy
}

export function isEmptyObject(obj: any) {
  let name: any
  for (name in obj) return false
  return true
}

export function isValidDate(date: Date) {
  return date instanceof Date && !isNaN(date.getTime())
}

export function obj2Str(obj: any) {
  const p = {}
  for (const key of Object.keys(obj)) {
    if (obj[key] || obj[key] === 0) {
      if (obj[key].toString() !== "") {
        p[key] = obj[key].toString()
      }
    }
  }
  return p
}

export function str2arr(str: string, sepr: string = ",") {
  return str.replace(/[\r\n\s]/g, "").split(sepr)
}

export function getScrollbarWidth() {
  const scrollDiv = document.createElement("div")
  scrollDiv.style.cssText = "width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;"
  document.body.appendChild(scrollDiv)
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)
  return scrollbarWidth
}

export function distance(lat1, lon1, lat2, lon2, unit) {
  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0
  } else {
    const radlat1 = Math.PI * lat1 / 180
    const radlat2 = Math.PI * lat2 / 180
    const theta = lon1 - lon2
    const radtheta = Math.PI * theta / 180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit === "K") {
      dist = dist * 1.609344
    }
    if (unit === "N") {
      dist = dist * 0.8684
    }
    return dist
  }
}

// #endregion -----------------------------------------------------------------