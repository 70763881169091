import { LotDto } from "./lot.dto"
import { Badge } from "../models/badge"
import _ from "lodash"
import { KeyMap } from "../models"

export class SkuDto {

  public static Keys = ['iProofId', 'name', 'description', 'url', 'tags', 'keyValues'];

  iProofId: string
  name: string
  description?: string
  url?: string
  hierarchy?: number
  hierarchyName: string
  lots: LotDto[] = []
  tags: string[] = []
  keyValues?: KeyMap<string> = {}
  companyId: number = 0
  companyName: string

  // derived
  numItems: number = 0
  badges?: Badge[] = []
  match?: boolean

  constructor(some?: Partial<SkuDto>) {
    Object.assign(this, some);
  }
}
