export class __PagingDataSource {
    rows: any[];
    search?: string;
    sortKey?: string;
    sortDir?: string;
    offset: number;
    limit: number;
    total?: number;

    constructor(some?: Partial<PagingDataSource>) {
        Object.assign(this, some);
    }
}

export class PagingDataSource {
    terms: string[] = []
    tags: string[] = []
    offset: number = 0
    limit: number = 1000
    total: number = 0
    data: any[] = []
    refresh: boolean = true

    constructor(some?: Partial<PagingDataSource>) {
        Object.assign(this, some);
    }
}
